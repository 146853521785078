import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import news from "../img/news.jpg";
import { Link, graphql } from 'gatsby'
import { Timeline } from "react-twitter-widgets";
library.add(fab)
library.add(fas)


export const FB = () =>
  <>
    <Helmet>
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v12.0"
        nonce="vpaScnsg"
      />
    </Helmet>

    <Layout>
    <div
          className="full-width-image-container-mini margin-top-0"
          style={{
            backgroundImage: `url(${news})`,
            backgroundSize: `cover`,
            backgroundPosition: `50% 60%`
          }}
        >
          <div
            className="full-width-image-container-mini"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              position: "absolute",
            }}
          >
            < div className="has-text-centered" >
              <strong
                className="is-size-3-desktop is-size-5-touch"
                style={{
                  color: 'white',
                  padding: '1rem',
                }}
              >
                ニュース
              </strong>
              <br />
              <p
                className="is-size-4-desktop is-size-6-touch"
                style={{
                  color: 'white',
                  padding: '1rem',
                }}
              >
                SNSで最新情報を更新しています。
              </p>
              <br />
              <p >
                <a href="https://www.facebook.com/inputssl" target="_blank" class="button is-primary is-inverted is-outlined" style={{ marginRight: '20px' }}>
                  <span class="icon">
                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                  </span>
                  <span>Facebook</span>
                </a>
                <a href="https://twitter.com/inputssl" target="_blank" class="button is-primary is-inverted is-outlined">
                  <span class="icon is-small">
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </span>
                  <span>Twitter</span>
                </a>
              </p>
            </div >
          </div>
        </div>
      <section className="section section--gradient">
        <div className="content has-text-centered" width="auto" height="auto">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="columns is-multiline">
                <div className="is-parent column is-6">
                  <Timeline
                    dataSource={{ sourceType: "profile", screenName: "inputssl" }}
                    options={{ width: "500", height: "800" }}
                  />
                </div>

                <div className="is-parent column is-6">

                  <div className="fb-page" data-href="https://www.facebook.com/inputssl" data-tabs="timeline" data-width="500" data-height="800" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>

  </>

export default FB